'use strict';

import sliderImage from './modules/sliderImage';
import langMenu from './modules/langMenu';
import sendForm from './modules/sendForm';
import menu from './modules/menu';
import up from './modules/up';
import inputMask from './modules/inputMask';
import startLang from './modules/startLang';
import accordeon from './modules/accordeon';

window.addEventListener('DOMContentLoaded', () => {
    startLang('.backdrop', '.langs-modal__close');

    const myCarouselElement = document.querySelector('#carouselExampleIndicators')
    const carousel = new bootstrap.Carousel(myCarouselElement);
    
    sliderImage('.documents__field-image', 'documents__field-image_active', '.raz', 'raz_active');

    langMenu();

    menu('.header__menu', 'header__menu_active', 'header__item-link', '.header__hamburger', 'header__hamburger_active', 'header__button_burger');

    sendForm('.connect__form', '.connect__button', 'connect__loader', 'connect__loader_active', '.modal__close', '.overlay', '.modal__info', 'overlay_show');    

    up('.pageup');

    inputMask('inputPhone', '.connect__button');

    accordeon('.questions__top-section', '.questions__bottom-section', '.questions__open', 'questions__top-section_active');
});