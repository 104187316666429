function sliderImage(photoSelector, activeClassPhoto, timerSelector, activeClassTimer) {
    const photos = document.querySelectorAll(photoSelector),
          timer = document.querySelectorAll(timerSelector);

    let counter = 0;
    
    setInterval(() => {
        photos[counter].classList.remove(activeClassPhoto);
        timer[counter].classList.remove(activeClassTimer);

        if (counter == photos.length - 1) {
            counter = 0;
        } else {
            counter++;
        }
        
        photos[counter].classList.add(activeClassPhoto);
        timer[counter].classList.add(activeClassTimer);
    }, 4000)
}

export default sliderImage;