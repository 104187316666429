function menu(menuSelector, menuClassActive, menuLinkClass, hamburgerSelector, hamburgerClassActive, buttonFormClass) {
    const menu = document.querySelector(menuSelector),
          hamburger = document.querySelector(hamburgerSelector);
    
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerClassActive);
        menu.classList.toggle(menuClassActive);
    });

    menu.addEventListener('click', (e) => {
        if (e.target.classList.contains(menuLinkClass) || e.target.classList.contains(buttonFormClass)) {
            hamburger.classList.toggle(hamburgerClassActive);
            menu.classList.toggle(menuClassActive);
        }
    });
}

export default menu;