function startLang(langModalSelector, closeSelector) {
    const langModal = document.querySelector(langModalSelector),
          closeBtn = document.querySelector(closeSelector);

    
    
    if (sessionStorage.getItem('firstStart') != 'false') {
        langModal.classList.add('backdrop_show');
        document.body.style.overflow = 'hidden';

        closeBtn.addEventListener('click', () => {
            closeModal();
        })

        function closeModal() {
            langModal.classList.remove('backdrop_show');
            document.body.style.overflow = '';
        }

        document.addEventListener('keydown', (e) => {
            if (e.code === "Escape" && langModal.classList.contains('backdrop_show')) {
                closeModal();
            }
        });
        sessionStorage.setItem('firstStart', 'false');
    }
}

export default startLang;