function langMenu() {   
    
    if (Cookies.get('googtrans') != 'null' && Cookies.get('googtrans') != 'undefined') {
        if ((Cookies.get('googtrans').slice(Cookies.get('googtrans').length - 2)) == 'uk') {
            document.querySelectorAll('[data-google-lang="ru"]').forEach(item => {
                item.querySelector('.language__text').textContent = 'Російський';
            });
            document.querySelector('.video__content_rus').style.display = 'inline';
            document.querySelector('.video__content_eng').style.display = 'none';
        }
        if ((Cookies.get('googtrans').slice(Cookies.get('googtrans').length - 2)) == 'ru') {
            document.querySelector('.video__content_rus').style.display = 'inline';
            document.querySelector('.video__content_eng').style.display = 'none';
        }
    } else {
        document.querySelector('.video__content_rus').style.display = 'inline';
        document.querySelector('.video__content_eng').style.display = 'none';
    }
}

export default langMenu;