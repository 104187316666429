function inputMask(inputPhoneSelector, submitSelector) {
    let inputPhone=document.getElementById(inputPhoneSelector);
    const submit = document.querySelector(submitSelector);
    inputPhone.oninput=()=>phoneMask(inputPhone)
    function phoneMask(inputEl) {
        let patStringArr = "+".split('');
        let val = inputEl.value;
        let arr = val.replace(/\D+/g, "").split('').splice(0);
        arr.forEach((s, i) => {
            patStringArr[i+1] = s;
        });
        inputEl.value = patStringArr.join('');
        if (inputEl.value.length < 2) {
            submit.disabled = true;
        } else {
            submit.disabled = false;
        }
    }

}

export default inputMask;