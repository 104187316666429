function accordeon(topSection, bottomSection, button, activeClassTop) {
    document.querySelectorAll(button).forEach(item => {
        item.addEventListener('click', (e) => {
            if (e.target.closest(topSection).classList.contains(activeClassTop)){
                e.target.closest(topSection).classList.remove(activeClassTop);
                e.target.closest(topSection).nextElementSibling.style.maxHeight = '0px';
            } else {
                document.querySelectorAll(topSection).forEach(item => {
                    item.classList.remove(activeClassTop);
                });
                document.querySelectorAll(bottomSection).forEach(item => {
                    item.style.maxHeight = '0px';
                });

                console.log(e.target.closest(topSection).nextElementSibling.children[0].clientHeight);
                const height = e.target.closest(topSection).nextElementSibling.children[0].clientHeight;
    
                e.target.closest(topSection).classList.add(activeClassTop);
                e.target.closest(topSection).nextElementSibling.style.maxHeight = `${height}px`;
            }
            
        });
    });
}

export default accordeon;